import React, { useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { isEmail } from 'validator';
import AuthService from '../services/auth.service';
//import api from '../services/api';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../common/img/b4_Logo_IhrNonProfit.svg';
import { MdLogin } from 'react-icons/md';
import { version } from '../version';
import '../common/css/login.css';
import UserService from '../services/user.service';

const required = (value) => {
    if (! value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (! isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const validPassword = (value) => {
    if (value.length < 6 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 20 characters.
            </div>
        );
    }
};

const Login = (props) => {
    const { t } = useTranslation();
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    /**
     * Handle email change
     */
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    /**
     * Handle password change
     */
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    /**
     * Attempts to sign in via the client and admin endpoint
     */
    const handleLogin = (e) => {
        e.preventDefault();

        setMessage('');
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.signinClientAndAdmin(email, password).then(() => {
                const user = AuthService.getCurrentUser();

                if (user.roles[0] === 'ROLE_CLIENT') {
                    props.history.push('/client');
                    window.location.href = '/client';
                } else if (user.roles[0] === 'ROLE_ADMIN') {
                    UserService.getPortalCustomers().then(response => {
                        AuthService.setAllPortalCustomers(response);

                        props.history.push('/admin');
                        window.location.href = '/admin';
                    }).catch(error => {
                        const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                        setLoading(false);
                        setMessage(errorMessage);
                    });
                }
            }, error => {
                const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setLoading(false);
                setMessage(errorMessage);
            });
        } else {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="sidenav"></div>

            <div className="main">
                <div className="col-md-12">
                    <Logo width={300} />
                    <h3 style={{color: '#6bd425', marginTop: '6%'}}>
                        Herzlich willkommen im Online Portal
                    </h3>

                    <div className="card card-container">
                        <Form onSubmit={handleLogin} ref={form}>

                            {/* Email */}
                            <div className="form-group" style={{ marginBottom: '5px' }}>
                                <label htmlFor="email" style={{ margin: '0px' }}>
                                    E-Mail:
                                </label>
                                <Input type="text" className="form-control" name="email" value={email} onChange={onChangeEmail} validations={[required, validEmail]} autoFocus style={{
                                    height: '27px',
                                    border: '1px solid #6bd425',
                                    borderRadius: '2px',
                                }} />
                            </div>

                            {/* Password */}
                            <div className="form-group" style={{ marginTop: '0px', marginBottom: '5px' }}>
                                <label htmlFor="password" style={{ margin: '0px' }}>
                                    {t('password')}
                                </label>
                                <Input type="password" className="form-control" name="password" value={password} onChange={onChangePassword} validations={[required, validPassword]} style={{
                                    height: '27px',
                                    border: '1px solid #6bd425',
                                    borderRadius: '2px',
                                }} />
                            </div>

                            {/* Login button */}
                            <div className="form-group">
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <button className="btn" disabled={loading} style={{
                                        border: 'none',
                                        backgroundColor: '#fafafa',
                                        color: '#6bd425',
                                        marginRight: '0px',
                                        paddingRight: '0px',
                                        alignSelf: 'flex-end',
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {loading && (<span className="spinner-border spinner-border-sm"></span>)}
                                            <span style={{ fontWeight: '700', fontSize: '1.3rem' }}>
                                                Anmelden
                                            </span>
                                            <MdLogin size={24} />
                                        </div>
                                    </button>
                                </div>
                            </div>

                            {/* Error message */}
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                        </Form>
                    </div>

                    <div style={{ position: 'fixed', bottom: '0' }}>
                        &#169; Copyright 2003-2022 base4IT GmbH (V{version})
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
