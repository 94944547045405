export const builderTranslations = {
  de: {
    Contact: "Kontakt",
    Organisation: "Organisation",
    Street: "Straße",
    Houseno: "Hausnummer",
    "ZIP code": "PLZ",
    City: "Ort",
    Salutation: "Anrede",
    "First Name": "Vorname",
    "Last Name": "Nachname",
    Title: "Titel",
    "E-mail": "E-Mail",
    "E-mail Person": "E-Mail Person",
    "E-mail Org": "E-Mail Org",
    Phone: "Telefon",
    "Phone Person": "Telefon Person",
    "Phone Org": "Telefon Org",
    "Mobile phone": "Mobiltelefon",
    IBAN: "IBAN",
    "Account Owner": "Kontoinhaber",
    Project: "Förderung",
    "Project Name": "Projektbezeichnung",
    "Project Description": "Kurzbeschreibung",
    "Project start": "Projektbeginn",
    "Project end": "Projektende",
    "Nonprofit project": "Gemeinnütziges Vorhaben",
    "Charitable objective": "Zwecke nach AO",
    "Project has started": "Maßnahme begonnen",
    "Funding total": "Finanzierung gesamt",
    "Costs total": "Projektkosten gesamt",
    "Funding Total": "Finanzierung gesamt",
    "Costs Total": "Projektkosten gesamt",
    "Own funds": "Eigenmittel",
    "Requested amount": "Anfragebetrag",
    "Notice of Excemption": "Freistellungsbescheid",
    "More files": "Zusätzliche Dateien",
    "Cost and Finance": "Kosten und Finanzierung",
    Costs: "Kosten",
    Funding: "Finanzierung",
    "Type of cost": "Kostenart",
    Amount: "Betrag",
    "Total Funding": "Summe Finanzierung",
    "Open balance": "Offene Differenz",
    applied: "beantragt",
    confirmed: "zugesagt",
    existing: "vorhanden",
    More: "Weitere",
    "Open balance must be 0":
      "Kosten und Finanzierung müssen ausgeglichen sein",

    "Customer specific fields": "Zusatzfelder",
    Content: "Freier Textbereich",
    "Text Field": "Texteingabe (einzeilig)",
    "Text Area": "Texteingabe (mehrzeilig)",
    Number: "Zahl",
    "Date / Time": "Datum / Zeit",
    Select: "Auswahlliste",

    Other: "Sonstige",
    "Application Location": "Ort",
    "Application Date": "Datum",
    Confirm: "Bestätigung Richtigkeit",
    Edit: "Bearbeiten",
    Move: "Verschieben",
    Copy: "Kopieren",
    Remove: "Entfernen",
    cancel: "Abbrechen",
    next: "Nächste",
    previous: "Vorherige",
    submit: "Absenden",
    Submit: "Absenden",
    Page: "Seite",
    "Select Boxes": "Dimension",


    //tooltips
    "Name of your organisation": "Organisationsname des Antragstellers",
    "Street of your organisation": "Straße des Antragsstellers",
    "House number of your organisation": "Hausnummer des Antragsstellers",
    "Zip code of your organisation": "Postleitzahl des Antragsstellers",
    "City of your organisation": "Ort des Antragsstellers",
    "Salutation of the contact person": "Anrede des Ansprechpartner",
    "First name of the contact person": "Vorname des Ansprechpartners",
    "Last name of the contact person": "Nachname des Ansprechpartners",
    "Title of the contact person": "Titel des Ansprechpartners",
    "E-Mail of the contact person": "E-Mail-Adresse des Ansprechpartners",
    "E-Mail of the organisation": "E-Mail-Adresse der Organisation",
    "Phone number of the contact person": "Telefonnummer des Ansprechpartners",
    "Phone number of the organisation": "Telefonnummer der Organisation",
    "Mobile phone number of the contact person": "Mobiltelefonnummer des Ansprechpartners",
    "Bank account of your organisation": "IBAN des Antragstellers",
    "Account holder": "Inhaber der Bankverbindung",
    "Project name": "Bezeichnung des Projektes, das gefördert werden soll",
    "Project description": "Beschreibung des zu fördernden Projektes",
    "Project starts at": "Beginn des Projektes, das gefördert werden soll",
    "Project will be finished by":
      "Ende des Projektes, das gefördert werden soll",
    "Is it a non-profit project?": "Ist das zu fördernde Projekt gemeinnützig?",
    "Charitable objective for the project":
      "Stiftungszweck nach Abgabenordnung",
    "Has the project already begun?":
      "Wurde mit der Maßnahme bereits begonnen?",
    "Total funding ": "Gesamtbetrag der Finanzierung",
    "Total project costs": "Gesamtbetrag der Kosten",
    "Amount requested": "Angefragter Betrag",
    "Notice of excemption": "Freistellungsbescheid",
    "Additional files": "Zusätzliche Dateien",
    "City of your current location": "Ort der Ausfüllung des Antragsstellers",
    "Date of application": "Datum der Ausfüllung der Antragsstrecke",
    "Confirmation, that all information is correct":
      "Bestätigung das alle Angaben der Richtigkeit entsprechen",

    "File Name": "Dateiname",
    Size: "Größe",
    "Choose title or enter an individual one": "Titel auswählen oder eingeben",
    "Enter title:": "Titel eingeben",
    "Enter title": "Titel eingeben",
    Yes: "Ja",
    No: "Nein",
    // "Drag and Drop a form component": "DRAG {GER}",
    // add remaining translations for error messages
    // "Please check the form and correct all errors before submitting.": "test",
    // "Please fix the following errors before submitting. ": "test {GER}",
    // "Organisation is required": "ORG{GER}",

    "Drop files to attach,": "Datei hier ablegen,",
    or: "oder",
    browse: "suchen",
    Homepage: "Website",
    "Website of your organisation": "Website ihrer Organisation",
    "File size exceeds maximum limit of 5MB.":
      "Die Datei überschreitet die zulässige Größe von 5 MB.",
    "File is too big; it must be at most 5MB":
      "Die Datei überschreitet die zulässige Größe von 5 MB",
    "Maximum number of files is 5": "Die maximale Anzahl von Dateien ist 5",
    "File is the wrong type; it must be .jpg, .jpeg, .pdf":
      "Datei hat den falschen Typ. Zugelassen sind nur .jpg, .jpeg und .pdf",
    "You can upload a maximum of 5 files. The files must be of the type 'jpg', 'jpeg' or 'pdf' and must not be larger than 1 MB each.":
      "Sie können maximal 5 Dateien hochladen. Die Dateien müssen vom Typ 'jpg', 'jpeg' oder 'pdf' sein und dürfen jeweils nicht größer als 1 MB sein.",
    invalid_email: "Gültige E-Mail notwendig",
    required: "{{field}} erforderlich",
    "Department in your organiation": "Abteilung in der Organisation",
    "Your job position": "Ihre Position in der Organisation",
    confirmCancel: "Wollen Sie wirklich abbrechen?",
    min: "{{field}} kann nicht kleiner als {{min}} sein.",
    // max: "{{field}} german for cannot be greater than {{max}}",
    error: "Bitte korrigieren Sie folgende Angaben vor dem Senden.",
    submitError: "Bitte prüfen und korrigieren Sie die Felder um es zu senden.",
  },
  en: {
    Organisation: "Organisation",
    "File is too big; it must be at most 5MB":
      "File size exceeds maximum limit of 5MB.",
    Abteilung: "Department",
    Leerzeile: "Empty line",

    //new translations added after using the default label names in German

    Straße: "Street",
    Hausnummer: "Houseno",
    PLZ: "ZIP code",
    Ort: "City",
    Anrede: "Salutation",
    Vorname: "First Name",
    Nachname: "Last Name",
    Telefon: "Phone",
    Mobiltelefon: "Mobile phone",
    Kontoinhaber: "Account Owner",
    Projektbezeichnung: "Project Name",
    Kurzbeschreibung: "Project Description",
    Projektbeginn: "Project start",
    Projektende: "Project end",
    "Gemeinnütziges Vorhaben": "Nonprofit project",
    "Zwecke nach AO": "Charitable objective",
    "Maßnahme begonnen": "Project has started",
    "Finanzierung gesamt": "Funding total",
    "Projektkosten gesamt": "Costs total",
    Eigenmittel: "Own funds",
    Anfragebetrag: "Requested amount",
    Freistellungsbescheid: "Notice of Excemption",
    Anderes: "More files",

    // cost & finance
    "Kosten und Finanzierung": "Cost and Finance",
    Kosten: "Costs",
    Finanzierung: "Funding",
    Kostenart: "Type of cost",
    Betrag: "Amount",
    "Summe Finanzierung": "Total Funding",
    "Offene Differenz": "Open balance",
    beantragt: "applied",
    zugesagt: "confirmed",
    vorhanden: "existing",
    Weitere: "More",

    Datum: "Application Date",
    "Bestätigung Richtigkeit": "Confirm",
  },
};
