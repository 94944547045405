import React, { useState, useEffect } from "react";
import { List } from "./styles";
import { DataGrid } from "@mui/x-data-grid";
import UserService from "../../services/user.service";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";

const TableAllUsers = () => {
    const [customersData, setCustomersData] = useState([]);
    const allPortalCustomers = AuthService.getAllPortalCustomers();

    const columns = [
        {
            field: "clientID",
            headerName: "Client ID",
            width: 100,
        },

        {
            field: "clientName",
            headerName: "Client Name",
            width: 300,
            renderCell: (params) => {
                return (
                    <Link
                        // to={`/formView/${params.row.id}`}
                        to={{
                            pathname: "/client",
                            state: { clientID: params.row.clientID },
                        }}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    >
                        {params.row.clientName}
                    </Link>
                );
            },
        },
        {
            field: "maxFormCount",
            headerName: "Max Forms",
            width: 100,
        },
        {
            field: "activeForms",
            headerName: "Active Forms",
            width: 300,
        },
    ];

    useEffect(() => {
        UserService.activeFormsPerClient().then(response => {

            //
            // Merge the active forms count with the customer data
            const tableData = allPortalCustomers.map(customer => {
                return {
                    ...customer,
                    activeForms: response.hasOwnProperty(customer.clientID) ? response[customer.clientID] : 0
                };
            });

            setCustomersData(tableData);
        }, error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            console.log("resMessage:", resMessage);
        });
    }, [allPortalCustomers]);

    return (
        <List>
            <DataGrid
                columns={columns}
                rows={customersData}
                autoHeight
                hideFooter
                getRowId={(row) => row.clientID}
                checkboxSelection
                sx={{
                    background: "white",
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                }}
            />
        </List>
    );
};

export default TableAllUsers;
