import React, { useState, useEffect } from "react";
import FormBuilder from "@formio/react/lib/components/FormBuilder";
import "./formBuilder.scss";
import produce, { setAutoFreeze } from "immer";
import { Modal } from "react-bootstrap";
import { Formio } from "formiojs";
import { Container } from "../../common/styles/BuilderConfig";
import UserService from "../../services/user.service";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../tableClient/styles";
import { MdCheck, MdAddCircle, MdClose, MdDelete } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import eventBus from "../../common/EventBus";
import CheckUnsavedChanges from "../../common/CheckUnsavedChanges";
import {
  applicantGroup,
  projectGroup,
  customerSpecificGroup,
  othersGroup,
} from "../../common/builderComponents/builderComponents";
import { builderTranslations } from "../../common/translations/builderTranslations";
import AuthService from "../../services/auth.service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { QuillEditor } from "../QuillEditor/QuillEditor.js";

setAutoFreeze(false);

const CustomFormBuilder = (props) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const formParams = useParams();
  const location = useLocation();

  // need to find a better way to fix 'missing projectID'
  const history = useHistory();
  //const formio = new Formio("https://examples.form.io/example");
  const [showModal, setShowModal] = useState(
    formParams.type === "n" ? true : false
  );

  const user = AuthService.getCurrentUser();
  const isAdmin = user.roles.includes("ROLE_ADMIN");

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [formTitle, setFormTitle] = useState(t("formTitle"));
  const [logo, setLogo] = useState(null);
  const [logoInactive, setLogoInactive] = useState(null);
  const [logoSuccess, setLogoSuccess] = useState(null);
  const [logoPosition, setLogoPosition] = useState("above");
  const [logoAllign, setLogoAllign] = useState("center");
  const [logoWidth, setLogoWidth] = useState("100px");
  const [formWidth, setFormWidth] = useState("50%");
  const [topPadding, setTopPadding] = useState("0px");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const [formContainerColor, setFormContainerColor] = useState("#ffffff");
  const [formPaginationColor, setFormPaginationColor] = useState("#ffffff");
  const [formPaginationTextColor, setFormPaginationTextColor] =
    useState("#000000");
  const [formBarColor, setFormBarColor] = useState("#ffffff");
  const [formBarTextColor, setFormBarTextColor] = useState("#000000");
  const [customerMailbox, setCustomerMailbox] = useState("");
  const [applicationType, setApplicationType] = useState("foerderung");
  const [foundationNumber, setFoundationNumber] = useState("");

  const [logoFlag, setLogoFlag] = useState(null);
  const [updateDisplay, setUpdateDisplay] = useState("form");
  const [updateTheme, setUpdateTheme] = useState("primary");
  const [updateFontSize, setUpdateFontSize] = useState("h6");
  const [updateHeader, setUpdateHeader] = useState(t("header"));
  const [updateFooter, setUpdateFooter] = useState(t("footer"));
  const [reqLogin, setReqLogin] = useState("false");
  const [attachmentExtension, setAttachmentExtension] = useState("json");
  const [emailEncryption, setEmailEncryption] = useState("false");
  const [emailPGPPublicKey, setEmailPGPPublicKey] = useState("");
  const [pdfName, setPdfName] = useState("");

  const status = "Inactive";
  const [flag, setFlag] = useState("off");
  const licence_Kundenname = AuthService.getCurrentUser().licence_Kundenname;
  const client_id = AuthService.getCurrentUser().clientID;

  let checkUnsavedHook = CheckUnsavedChanges();
  const user_email = AuthService.getCurrentUser().email;

  const [emailSalutationType, setEmailSalutationType] = useState("formal_1");

  const [emailSubject, setEmailSubject] = useState(
    `Ihr Antrag bei ${licence_Kundenname}`
  );

  const [emailTemplate, setEmailTemplate] =
    useState(`<p> Sehr geehrter Antragsteller, <p>
  <br>
  <p>vielen Dank für die Übermittlung Ihres Antrages.</p> 
  <p>Ihre übermittelten Daten finden Sie nochmals als Anhang zu dieser Nachricht.</p>
  <p>Ihr Antrag wird nun intern geprüft und wir werden uns zeitnah mit Ihnen in Verbindung setzten.</p>
  <br>
  <p>Herzlichen Dank</p>
`);

  const [formMessages, setFormMessages] = useState({
    submissionSuccessMessage:
      "Danke, Ihr Antrag wurde übermittelt und wir haben Ihnen eine Bestätigung zugeschickt. Bitte prüfen Sie auch Ihren SPAM Ordner.",
    inactiveFormMessage: "Das Formular ist derzeit nicht erreichbar.",
  });

  const updateFormMessages = (key, value) => {
    setFormMessages((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [schema, setSchema] = useState({
    display: "form",
    components: [
      {
        title: "Page 1",
        label: "Page 1",
        type: "panel",
        key: "page1",
        customClass: updateFontSize,
        theme: "",
        components: [
          {
            type: "content",
            key: "headerContent",
            html: `<div style="margin:0px; "> 
                      <div style="width:100%; display:flex; flex-direction:row ; justify-content: ${logoAllign};">
                        <img src=" ${
                          logoPosition === "above" ? null : logo
                        }" style="width:5%;"  alt="">
                      </div>
                      <h5> ${updateHeader} </h5>
                   </div>`,
            label: "LogoContent",
          },
          {
            type: "fieldset",
            label: " ",
          },

          {
            type: "content",
            html: `<p>${updateFooter}</p>`,
            label: "FContent",
            key: "FContent",
          },
          {
            type: "button",
            label: "Submit",
            key: "submit",
            disableOnInvalid: false,
            input: true,
            tableView: false,
            attributes: {
              style: `background-color: ${formBarColor}; border-color:black; color: black;`,
            },
          },
        ],
      },
    ],
  });

  const [formProps, setFormProps] = useState({
    logo_position: logoPosition,
    logo_allign: logoAllign,
    logo_width: logoWidth,
    form_width: formWidth,
    top_padding: topPadding,
    background_color: backgroundColor,
    form_container_color: formContainerColor,
    form_pagination_color: formPaginationColor,
    form_bar_color: formBarColor,
    form_bar_text_color: formBarTextColor,
    form_pagination_text_color: formPaginationTextColor,
    customer_mailbox: customerMailbox,
    application_type: applicationType,
    foundation_number: foundationNumber,
    theme_colour: updateTheme,
    font_size: updateFontSize,
    header: updateHeader,
    footer: updateFooter,
    email_salutation_type: emailSalutationType,
    email_subject: emailSubject,
  });

  useEffect(() => {
    if (formParams.type === "n" || formParams.type === "t") {
      handleUpdate(
        updateDisplay,
        updateTheme,
        updateFontSize,
        updateHeader,
        updateFooter,
        logoPosition,
        logoAllign,
        logoWidth
      );
    } else {
      //formParams.type is initialised with formId
      UserService.getForm(formParams.type).then(
        (response) => {
          setLogo(response.logo);
          setSchema(response.schema);
          setReqLogin(response.req_login);
          setAttachmentExtension(response.attachment_extension);
          setUpdateDisplay(response.schema.display);
          setEmailTemplate(response.email_template);
          setEmailEncryption(response.email_encryption + "");
          setEmailPGPPublicKey(response.email_pgp_public_key);
          setPdfName(response.pdf_name);

          if (response.logo_inactive) {
            setLogoInactive(response.logo_inactive);
          }

          if (response.logo_success) {
            setLogoSuccess(response.logo_success);
          }

          if (response.form_messages) {
            updateFormMessages(
              "submissionSuccessMessage",
              response.form_messages.submissionSuccessMessage
            );
            updateFormMessages(
              "inactiveFormMessage",
              response.form_messages.inactiveFormMessage
            );
          }

          setFormTitle(props.title);
          setLogoPosition(response.formProps.logo_position);
          setLogoAllign(response.formProps.logo_allign);
          setLogoWidth(response.formProps.logo_width);
          setFormWidth(response.formProps.form_width);
          setTopPadding(response.formProps.top_padding);
          setBackgroundColor(response.formProps.background_color);
          setFormContainerColor(response.formProps.form_container_color);
          setFormPaginationColor(response.formProps.form_pagination_color);
          setFormPaginationTextColor(
            response.formProps.form_pagination_text_color
          );
          setFormBarColor(response.formProps.form_bar_color);
          setFormBarTextColor(response.formProps.form_bar_text_color);
          setCustomerMailbox(response.formProps.customer_mailbox);
          setApplicationType(response.formProps.application_type || 'foerderung');
          setFoundationNumber(response.formProps.foundation_number || '');
          setUpdateTheme(response.formProps.theme_colour);
          setUpdateFontSize(response.formProps.font_size);
          setUpdateHeader(response.formProps.header);
          setUpdateFooter(response.formProps.footer);
          setEmailSalutationType(response.formProps.email_salutation_type);
          setEmailSubject(response.formProps.email_subject);

          handleUpdate(
            response.schema.display,
            response.formProps.theme_colour,
            response.formProps.font_size,
            response.formProps.header,
            response.formProps.footer,
            response.formProps.logo_position,
            response.formProps.logo_allign,
            response.formProps.logo_width,
            response.logo
          );

          setFormProps({
            logo_position: response.formProps.logo_position,
            logo_allign: response.formProps.logo_allign,
            logo_width: response.formProps.logo_width,
            form_width: response.formProps.form_width,
            top_padding: response.formProps.top_padding,
            background_color: response.formProps.background_color,
            form_container_color: response.formProps.form_container_color,
            form_pagination_color: response.formProps.form_pagination_color,
            form_pagination_text_color:
              response.formProps.form_pagination_text_color,
            form_bar_color: response.formProps.form_bar_color,
            form_bar_text_color: response.formProps.form_bar_text_color,
            customer_mailbox: response.formProps.customer_mailbox,
            application_type: response.formProps.application_type || 'foerderung',
            foundation_number: response.formProps.foundation_number || '',
            theme_colour: response.formProps.theme_colour,
            font_size: response.formProps.font_size,
            header: response.formProps.header,
            footer: response.formProps.footer,
            email_salutation_type: response.formProps.email_salutation_type,
            email_subject: response.formProps.email_subject,
          });

          if (response.formProps.logo_position === "above") {
            setLogoFlag(true);
          }

          if (response.schema.display === "form") {
            const submitButton = document.querySelector(
              '[name="data[submit]"]'
            );

            if (submitButton) {
              submitButton.setAttribute(
                "style",
                `background-color: ${response.formProps.form_bar_color}; border-color:black; color: black;`
              );
            }
          }

          handleClose();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log("resMessage:", resMessage);
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** on submitDispatched triggered from App.js the flag is set to 'on'
   *  changing the state of the flag triggers the next useEffect which
   *  calls the handleCreateForm() function => form is submitted
   */
  useEffect(() => {
    eventBus.on("submitDispatchBuilder", (data) => {
      setFlag(data.message);
    });

    return () => {
      eventBus.remove("submitDispatchBuilder");
    };
  }, []);

  useEffect(() => {
    if (flag === "off") {
      return;
    } else {
      handleCreateForm(
        formTitle,
        logo,
        schema,
        status,
        reqLogin,
        emailTemplate,
        formMessages,
        formProps,
        licence_Kundenname,
        user_email,
        logoInactive,
        logoSuccess,
        isAdmin,
        client_id,
        attachmentExtension,
        emailEncryption,
        emailPGPPublicKey,
        pdfName
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  const handleCreateForm = (
    formTitle,
    logo,
    schema,
    status,
    reqLogin,
    emailTemplate,
    formMessages,
    formProps,
    licence_Kundenname,
    user_email,
    logoInactive,
    logoSuccess,
    isAdmin,
    client_id,
    attachmentExtension,
    emailEncryption,
    emailPGPPublicKey,
    pdfName
  ) => {
    if (formTitle === "") {
      alert("Cannot save form. Please input Form Title.");
    } else {
      if (
        (isAdmin && formParams.type === "n") ||
        (isAdmin && location.state?.isCopyClient)
      ) {
        const externalCientID = localStorage.getItem("externalClientID");

        const client_name = localStorage.getItem("clientName");

        UserService.createFormViaAdmin(
          formTitle,
          logo,
          schema,
          status,
          reqLogin,
          emailTemplate,
          formMessages,
          formProps,
          client_name,
          user_email,
          logoInactive,
          logoSuccess,
          isAdmin,
          externalCientID,
          attachmentExtension,
          emailEncryption,
          emailPGPPublicKey,
          pdfName
        )
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("resMessage:", resMessage);
            }
          )
          .then(() => {
            checkUnsavedHook.onPristine();
          })
          .then(() => {
            history.push({
              pathname: "/client",
              state: { clientID: externalCientID },
            });
          });
      } else {
        UserService.createForm(
          formTitle,
          logo,
          schema,
          status,
          reqLogin,
          emailTemplate,
          formMessages,
          formProps,
          licence_Kundenname,
          user_email,
          logoInactive,
          logoSuccess,
          isAdmin,
          client_id,
          attachmentExtension,
          emailEncryption,
          emailPGPPublicKey,
          pdfName
        )
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("resMessage:", resMessage);
            }
          )
          .then(() => {
            checkUnsavedHook.onPristine();
          })
          .then(() => {
            if (isAdmin) {
              history.push("/admin");
            } else {
              history.push("/client");
            }
          });
      }
    }
  };

  const handleLogoBase64 = (image) => {
    var reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      setLogo(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const handleLogoInactiveBase64 = (image) => {
    var reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      setLogoInactive(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const handleLogoSuccessBase64 = (image) => {
    var reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      setLogoSuccess(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const handleUpdate = (
    updateDisplay,
    updateTheme,
    updateFontSize,
    updateHeader,
    updateFooter,
    logoPosition,
    logoAllign,
    logoWidth,
    logo
  ) => {
    checkUnsavedHook.onDirty();
    setSchema(
      produce((draft) => {
        draft.components.forEach((element) => {
          element.theme = updateTheme;
          element.customClass = updateFontSize;

          element.components?.forEach((x) => {
            if (x.type === "content" && x.label === "LogoContent") {
              x.html = `<div style="margin:0px; "> 
                          <div style="width:100%; display:flex; flex-direction:row ; justify-content: ${logoAllign};">
                            <img src=" ${
                              logoPosition === "above" ? null : logo
                            }" style="width: ${logoWidth};"  alt="">
                          </div>
                          <h5> ${updateHeader} </h5>
                        </div>`;
            }
            if (x.type === "content" && x.label === "FContent") {
              x.html = updateFooter;
            }
            if (x.type === "button" && updateDisplay === "wizard") {
              x.hidden = true;
              x.customClass = "d-none";
            } else if (x.type === "button" && updateDisplay === "form") {
              x.hidden = false;
              x.customClass = "";
            }
          });
        });

        draft.display = updateDisplay;

        if (updateDisplay === "form") {
          draft.components[0].title = " ";
        } else if (updateDisplay === "wizard" && formParams.type === "n") {
          draft.components[0].title = "Page 1";
        }
      })
    );
    const panelSchema = Formio.Components.components.panel.schema;

    Formio.Components.components.panel.schema = (...extend) => {
      var schema = panelSchema(extend);
      schema.theme = updateTheme;
      schema.customClass = updateFontSize;
      schema.components[0] = {
        type: "content",
        html: `<div style="margin:0px; "> 
                  <div style="width:100%; display:flex; flex-direction:row ; justify-content: ${logoAllign};">
                    <img src=" ${
                      logoPosition === "above" ? null : logo
                    }" style="width:${logoWidth};"  alt="">
                  </div>
                  <h5> ${updateHeader} </h5>
               </div>`,
        label: "LogoContent",
      };

      schema.components[1] = {
        type: "fieldset",
        label: " ",
      };
      schema.components[2] = {
        type: "content",
        html: `<p>${updateFooter}</p>`,
        label: "FContent",
      };

      return schema;
    };

    ["textfield", "number", "select", "email"].forEach((name) => {
      var originalEditForm = Formio.Components.components[name].editForm;

      Formio.Components.components[name].editForm = (...extend) => {
        var editForm = originalEditForm(extend);
        const cssComponent = Formio.Utils.getComponent(
          editForm.components,
          "customClass"
        );
        cssComponent.weight = 1;
        cssComponent.label = "Field width";
        cssComponent.type = "select";
        cssComponent.placeholder = "Width";
        cssComponent.tooltip = "Field Width";
        cssComponent.dataSrc = "values";
        cssComponent.data = {
          values: [
            { label: "25%", value: "w-25" },
            { label: "50%", value: "w-50" },
            { label: "75%", value: "w-75" },
            { label: "100%", value: "w-100" },
          ],
        };

        return editForm;
      };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (logoPosition === "above") {
      setLogoFlag(true);
    } else {
      setLogoFlag(false);
    }

    setFormProps({
      logo_position: logoPosition,
      logo_allign: logoAllign,
      logo_width: logoWidth,
      form_width: formWidth,
      background_color: backgroundColor,
      form_container_color: formContainerColor,
      form_pagination_color: formPaginationColor,
      form_pagination_text_color: formPaginationTextColor,
      form_bar_color: formBarColor,
      form_bar_text_color: formBarTextColor,
      customer_mailbox: customerMailbox,
      application_type: applicationType,
      foundation_number: foundationNumber,
      top_padding: topPadding,
      theme_colour: updateTheme,
      font_size: updateFontSize,
      header: updateHeader,
      footer: updateFooter,
      email_salutation_type: emailSalutationType,
      email_subject: emailSubject,
    });

    handleUpdate(
      updateDisplay,
      updateTheme,
      updateFontSize,
      updateHeader,
      updateFooter,
      logoPosition,
      logoAllign,
      logoWidth,
      logo
    );

    handleClose();
  };

  useEffect(() => {
    const submitButton = document.querySelector('[name="data[submit]"]');

    if (submitButton) {
      submitButton.setAttribute(
        "style",
        `background-color: ${formBarColor}; border-color:black; color: black;`
      );
    }
  }, [formBarColor]);

  return (
    <>
      {checkUnsavedHook.routerPrompt}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t("generalFormSettings")}</Modal.Title>
          <button
            className="btn"
            style={{ padding: "4px", alignItems: "center" }}
            onClick={handleClose}
          >
            <MdClose />
          </button>
        </Modal.Header>

        <Modal.Body>
          <Tabs
            defaultActiveKey="form-settings"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="form-settings" title={t("formSettings")}>
              <form onSubmit={handleFormSubmit}>
                <div
                  className="form-group"
                  style={{ display: "flex", justifyContent: `${logoAllign}` }}
                >
                  <div
                    style={{
                      border: "2px solid #67D525",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: `${logoWidth}`,
                      }}
                    >
                      {logo ? (
                        <img
                          alt="Not found."
                          style={{
                            display: "block",
                            width: "100%",
                            height: "auto",
                          }}
                          src={logo}
                        />
                      ) : (
                        <div style={{ height: "100px" }}>
                          <label
                            htmlFor="files"
                            className="btn"
                            style={{
                              width: "100%",
                              paddingTop: "15px",
                            }}
                          >
                            <MdAddCircle size={27} />
                            <div>Logo</div>
                          </label>
                          <input
                            id="files"
                            style={{ visibility: "hidden" }}
                            type="file"
                            name="logo"
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) => {
                              if (event.target.files[0].size > 2097152) {
                                alert("Logo size needs to be less than 2MB.");
                                event.target.value = null;
                              } else {
                                handleLogoBase64(event.target.files[0]);
                              }
                            }}
                          ></input>
                        </div>
                      )}
                    </div>
                    <div>
                      {logo && (
                        <button
                          onClick={() => setLogo(null)}
                          style={{ width: `${logoWidth}` }}
                        >
                          <MdDelete size={23} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group" style={{ display: "grid", marginBottom: "0px" }}>
                  <label className="d-inline-block" style={{ marginBottom: "0px" }}>
                    <strong>{t("form")}:</strong>
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input required defaultValue={formTitle} className="form-control lg-1" style={{ border: "2px solid #67D525" }} onChange={(e) => setFormTitle(e.target.value)} />
                </div>

                <div className="form-group" style={{ display: "grid", marginBottom: "0px" }}>
                  <label className="d-inline-block" style={{ marginBottom: "0px" }}>
                    <strong>Mailbox: </strong>
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input required type="email" defaultValue={customerMailbox} className="form-control lg-1" style={{ border: "2px solid #67D525" }} onChange={(e) => setCustomerMailbox(e.target.value)} />
                </div>

                <div className="form-group" style={{ display: "grid" }}>
                  <label className="d-inline-block" style={{ marginBottom: "0px" }}>
                    <strong>Stiftungsnummer:</strong>
                  </label>
                  <input defaultValue={foundationNumber} className="form-control lg-1" style={{ border: "2px solid #67D525" }} onChange={(e) => setFoundationNumber(e.target.value)} />
                </div>

                <div className="form-group" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                  <label className="d-inline-block" style={{ marginBottom: "0px" }}>
                    <strong>Antragstyp: </strong>
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select required value={applicationType} onChange={(e) => {
                      setApplicationType(e.target.value);
                      checkUnsavedHook.onDirty();
                    }}
                  >
                    <option value="foerderung">Förderung</option>
                    <option value="contact">Kontakt</option>
                    <option value="activity">Aktivität</option>
                  </select>
                </div>

                {logo && (
                  <>
                    <div
                      className="form-group"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 0.5fr 0.5fr",
                      }}
                    >
                      <label className="d-inline-block">
                        {" "}
                        <strong>Logo Position: </strong>{" "}
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setLogoPosition(e.target.value)}
                        value={logoPosition}
                      >
                        <option value="header">{t("header")}</option>
                        <option value="above">{t("aboveForm")} </option>
                      </select>
                      <select
                        className="form-select"
                        onChange={(e) => setLogoAllign(e.target.value)}
                        value={logoAllign}
                      >
                        <option value="flex-start">{t("left")}</option>
                        <option value="center">{t("center")}</option>
                        <option value="flex-end">{t("right")}</option>
                      </select>
                    </div>

                    <div
                      className="form-group"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <label className="d-inline-block">
                        {" "}
                        <strong>{t("logoWidth")}:</strong>{" "}
                      </label>

                      <select
                        className="form-select"
                        onChange={(e) => setLogoWidth(e.target.value)}
                        value={logoWidth}
                      >
                        <option value="50px">50px</option>
                        <option value="75px">75px </option>
                        <option value="100px">100px </option>
                        <option value="125px">125px </option>
                        <option value="150px">150px </option>
                        <option value="175px">175px </option>
                        <option value="200px">200px </option>
                      </select>
                    </div>
                  </>
                )}

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("topPadding")}: </strong>{" "}
                  </label>
                  <select
                    onChange={(e) => setTopPadding(e.target.value)}
                    value={topPadding}
                  >
                    <option value="0px">0px</option>
                    <option value="50px">50px</option>
                    <option value="100px">100px</option>
                    <option value="150px">150px</option>
                    <option value="200px">200px</option>
                    <option value="250px">250px</option>
                    <option value="300px">300px</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("formContainerWidth")}: </strong>{" "}
                  </label>
                  <select
                    onChange={(e) => setFormWidth(e.target.value)}
                    value={formWidth}
                  >
                    <option value="25%">25%</option>
                    <option value="50%">50%</option>
                    <option value="75%">75%</option>
                    <option value="100%">100%</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("formPageColour")}: </strong>{" "}
                  </label>

                  <input
                    type="color"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                    style={{ alignSelf: "center" }}
                  />
                </div>

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("formContainerColour")}: </strong>{" "}
                  </label>

                  <input
                    type="color"
                    value={formContainerColor}
                    onChange={(e) => setFormContainerColor(e.target.value)}
                    style={{ alignSelf: "center" }}
                  />
                </div>

                {updateDisplay === "wizard" && (
                  <>
                    <div
                      className="form-group"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <label className="d-inline-block">
                        {" "}
                        <strong>{t("formPaginationColour")}: </strong>{" "}
                      </label>

                      <input
                        type="color"
                        value={formPaginationColor}
                        onChange={(e) => setFormPaginationColor(e.target.value)}
                        style={{ alignSelf: "center" }}
                      />
                    </div>

                    <div
                      className="form-group"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <label className="d-inline-block">
                        {" "}
                        <strong>{t("formPaginationTextColour")}</strong>{" "}
                      </label>

                      <input
                        type="color"
                        value={formPaginationTextColor}
                        onChange={(e) =>
                          setFormPaginationTextColor(e.target.value)
                        }
                        style={{ alignSelf: "center" }}
                      />
                    </div>
                  </>
                )}

                {updateDisplay === "form" && (
                  <>
                    <div
                      className="form-group"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <label className="d-inline-block">
                        {" "}
                        <strong>{t("formBarColour")}: </strong>{" "}
                      </label>

                      <input
                        type="color"
                        value={formBarColor}
                        onChange={(e) => setFormBarColor(e.target.value)}
                        style={{ alignSelf: "center" }}
                      />
                    </div>

                    <div
                      className="form-group"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <label className="d-inline-block">
                        {" "}
                        <strong>{t("formBarTextColour")}:</strong>{" "}
                      </label>

                      <input
                        type="color"
                        value={formBarTextColor}
                        onChange={(e) => setFormBarTextColor(e.target.value)}
                        style={{ alignSelf: "center" }}
                      />
                    </div>
                  </>
                )}

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("generalFontSize")}:</strong>{" "}
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => setUpdateFontSize(e.target.value)}
                    value={updateFontSize}
                  >
                    <option value="h1">{t("large")}</option>
                    <option value="h3">{t("medium")}</option>
                    <option value="h6">{t("small")}</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("formDisplay")}:</strong>{" "}
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => setUpdateDisplay(e.target.value)}
                    value={updateDisplay}
                  >
                    <option value="form">{t("singlePage")}</option>
                    <option value="wizard">{t("multiPage")}</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("requireLogin")}:</strong>{" "}
                  </label>
                  <select
                    className="form-select"
                    style={{width: "100px"}}
                    onChange={(e) => setReqLogin(e.target.value)}
                    value={reqLogin}
                  >
                    <option value="true">{t("yes")}</option>
                    <option value="false">{t("no")}</option>
                  </select>
                </div>
                
                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("E-Mail PDF Antragsteller Anhangname")}:</strong>{" "}
                  </label>
                  <input className="form-select" onChange={(e) => setPdfName(e.target.value)} />
                </div>

                <div
                  className="form-group"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("E-Mail Anhangstyp")}:</strong>{" "}
                  </label>
                  <select
                    className="form-select" 
                    style={{width: "100px"}}
                    onChange={(e) => setAttachmentExtension(e.target.value)}
                    value={attachmentExtension}
                  >
                    <option value="json">json</option>
                    <option value="txt">txt</option>
                  </select>
                </div>

                <div
                  className="form-select"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("E-Mail-Verschlüsselung")}:</strong>{" "}
                  </label>
                  <select
                    className="form-select" 
                    style={{width: "100px"}}
                    onChange={(e) => setEmailEncryption(e.target.value)}
                    value={emailEncryption}
                  >
                    <option value="true">{t("yes")}</option>
                    <option value="false">{t("no")}</option>
                  </select>
                </div>

              {emailEncryption && emailEncryption === "true" ? (
                <div
                  className="form-select"
                >
                  <label className="d-inline-block">
                    {" "}
                    <strong>{t("PGP public key")}:</strong>{" "}
                  </label>
                  <br></br>
                  <textarea
                    type="text"
                    value={emailPGPPublicKey}
                    className="form-control col-xs-12"
                    onChange={(e) => {
                      setEmailPGPPublicKey(e.target.value);
                      checkUnsavedHook.onDirty();
                    }}
                  />
                </div>
              ) : ""}

                <div className="form-select">
                  <label
                    className="d-inline-block"
                    style={{ marginBottom: "0px" }}
                  >
                    {" "}
                    <strong>{t("header")}:</strong>{" "}
                  </label>
                  <br></br>
                  <textarea
                    type="text"
                    defaultValue={updateHeader}
                    className="form-control col-xs-12"
                    onChange={(e) => setUpdateHeader(e.target.value)}
                    style={{ border: "2px solid #67D525" }}
                  />
                </div>

                <div className="form-select">
                  <label
                    className="d-inline-block"
                    style={{ marginBottom: "0px" }}
                  >
                    {" "}
                    <strong>{t("footer")}:</strong>{" "}
                  </label>
                  <br></br>
                  <textarea
                    type="text"
                    defaultValue={updateFooter}
                    className="form-control col-xs-12"
                    onChange={(e) => setUpdateFooter(e.target.value)}
                    style={{ border: "2px solid #67D525" }}
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary mt-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MdCheck />
                  {t("apply")}
                </button>
              </form>
            </Tab>
            <Tab eventKey="form-messages" title={t("formMessagesTabTitle")}>
              <div className="form-group">
                <label
                  className="d-inline-block"
                  style={{ marginBottom: "0px" }}
                >
                  {" "}
                  <strong>{t("ifNotActive")}</strong>{" "}
                </label>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      border: "2px solid #67D525",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                      }}
                    >
                      {logoInactive ? (
                        <img
                          alt="Not found."
                          style={{
                            display: "block",
                            width: "100%",
                            height: "auto",
                          }}
                          src={logoInactive}
                        />
                      ) : (
                        <div style={{ height: "100px" }}>
                          <label
                            htmlFor="filesInc"
                            className="btn"
                            style={{
                              width: "100%",
                              paddingTop: "15px",
                            }}
                          >
                            <MdAddCircle size={27} />
                            <div>Logo</div>
                          </label>
                          <input
                            id="filesInc"
                            style={{ visibility: "hidden" }}
                            type="file"
                            name="logoInactive"
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) => {
                              if (event.target.files[0].size > 2097152) {
                                alert("Logo size needs to be less than 2MB.");
                                event.target.value = null;
                              } else {
                                handleLogoInactiveBase64(event.target.files[0]);
                              }
                            }}
                          ></input>
                        </div>
                      )}
                    </div>
                    <div>
                      {logoInactive && (
                        <button
                          onClick={() => setLogoInactive(null)}
                          style={{ width: "100%" }}
                        >
                          <MdDelete size={23} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <br></br>

                <textarea
                  type="text"
                  defaultValue={formMessages.inactiveFormMessage}
                  className="form-control col-xs-12"
                  maxLength="1000"
                  onChange={(e) =>
                    updateFormMessages("inactiveFormMessage", e.target.value)
                  }
                  style={{ border: "2px solid #67D525", height: "150px" }}
                />
              </div>

              <div className="form-group">
                <label
                  className="d-inline-block"
                  style={{ marginBottom: "0px" }}
                >
                  {" "}
                  <strong>{t("ifSuccess")}</strong>{" "}
                </label>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      border: "2px solid #67D525",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                      }}
                    >
                      {logoSuccess ? (
                        <img
                          alt="Not found."
                          style={{
                            display: "block",
                            width: "100%",
                            height: "auto",
                          }}
                          src={logoSuccess}
                        />
                      ) : (
                        <div style={{ height: "100px" }}>
                          <label
                            htmlFor="filesSuccess"
                            className="btn"
                            style={{
                              width: "100%",
                              paddingTop: "15px",
                            }}
                          >
                            <MdAddCircle size={27} />
                            <div>Logo</div>
                          </label>
                          <input
                            id="filesSuccess"
                            style={{ visibility: "hidden" }}
                            type="file"
                            name="logoSuccess"
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) => {
                              if (event.target.files[0].size > 2097152) {
                                alert("Logo size needs to be less than 2MB.");
                                event.target.value = null;
                              } else {
                                handleLogoSuccessBase64(event.target.files[0]);
                              }
                            }}
                          ></input>
                        </div>
                      )}
                    </div>
                    <div>
                      {logoSuccess && (
                        <button
                          onClick={() => setLogoSuccess(null)}
                          style={{ width: "100%" }}
                        >
                          <MdDelete size={23} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <br></br>
                <textarea
                  type="text"
                  defaultValue={formMessages.submissionSuccessMessage}
                  className="form-control col-xs-12"
                  maxLength="1000"
                  onChange={(e) =>
                    updateFormMessages(
                      "submissionSuccessMessage",
                      e.target.value
                    )
                  }
                  style={{ border: "2px solid #67D525", height: "150px" }}
                />
              </div>

              <button
                onClick={handleClose}
                className="btn btn-primary mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <MdCheck />
                {t("apply")}
              </button>
            </Tab>
            <Tab eventKey="email-template" title={t("emailTemplate")}>
              <div
                className="form-group"
                style={{ display: "grid", marginBottom: "20px" }}
              >
                <label
                  className="d-inline-block"
                  style={{ marginBottom: "0px" }}
                >
                  {" "}
                  <strong>{t("emailSubject")}</strong>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                  className="form-control lg-1"
                  style={{ border: "2px solid #67D525" }}
                  maxLength="500"
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <label className="d-inline-block">
                  {" "}
                  <strong>{t("salutationForm")}</strong>{" "}
                </label>

                <select
                  className="form-select"
                  onChange={(e) => setEmailSalutationType(e.target.value)}
                  value={emailSalutationType}
                >
                  <option value="formal_1">Sehr geehrte</option>
                  <option value="formal_2">Sehr verehrte</option>
                  <option value="informal_1">Liebe</option>
                  <option value="informal_2">Hallo</option>
                </select>
              </div>

              <QuillEditor
                setEmailTemplate={setEmailTemplate}
                emailTemplate={emailTemplate}
              />
              <button
                onClick={handleFormSubmit}
                className="btn btn-primary mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <MdCheck />
                {t("apply")}
              </button>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      <div>
        <div>
          <Container>
            <div style={{ width: "40%", marginLeft: "30%" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 12fr 1fr",
                  alignItems: "center",
                }}
              >
                <label className="d-inline-block">
                  <strong>{t("form")}: </strong>
                </label>
                <input
                  type="text"
                  value={formTitle}
                  onChange={(e) => setFormTitle(e.target.value)}
                  className="form-control"
                  style={{ border: "2px solid #67D525" }}
                />
                <ActionButton
                  style={{ marginLeft: "5px" }}
                  onClick={handleShow}
                >
                  <IoMdSettings />
                </ActionButton>
              </div>
            </div>
          </Container>

          {logo && logoFlag && (
            <Container style={{ justifyContent: `${logoAllign}` }}>
              <div
                style={{
                  border: "2px solid #6bd425",
                  backgroundColor: "white",
                  width: `${logoWidth}`,
                }}
              >
                <img
                  alt="Not found."
                  style={{ display: "block", width: "100%", height: "auto" }}
                  src={logo}
                />
              </div>
            </Container>
          )}
        </div>

        <FormBuilder
          form={schema}
          onChange={(schema) => {
            setSchema(schema);
          }}
          options={{
            noNewEdit: false,
            alwaysConfirmComponentRemoval: true,
            builder: {
              data: false,
              premium: false,
              advanced: false,
              layout: false,
              basic: false,
              group1: applicantGroup,
              group2: projectGroup,
              group3: customerSpecificGroup,
              group4: othersGroup,
            },
            language: i18n.resolvedLanguage,
            i18n: builderTranslations,
            languageOverride: {
              de: {
                decimalSeparator: ",",
                delimiter: ".",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default CustomFormBuilder;
